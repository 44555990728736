#kl-leaderboard-container {
	width: 100%;
	/* height: 100%; */
	z-index: 200;
	text-align: center;
	/* margin: 0 auto; */

	/* display: flex; */
	/* flex-direction: column; */
	/* align-items: flex-start; */
	/* justify-content: center; */
	/* flex-wrap: wrap; */
	overflow-y: scroll;
	/* For hiding scrollbars */
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

#kl-leaderboard-container::-webkit-scrollbar {
	display: none;
}

.kl-leaderboard-entryRow {
	width: 100%;
	display: flex;
	justify-content: center;
}

.kl-leaderboard-entryContainer {
	display: flex;

	color: white;
	font-size: 3.8vmin;
	font-weight: 500;

	text-align: left;
	z-index: 200;
	width: 75vmin;
	/* max-width: 800px; */

	margin: 10px auto;
}

.kl-leaderboard-entryTotal {
	margin-right: 10vmin;
	margin-left: 10vmin;
	display: inline-block;
}

.kl-leaderboard-name {
	text-align: left;
}
