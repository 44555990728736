#kl-myKudos-container {
}

#kl-myKudos-titleContainer {
	display: flex;
	justify-content: center;
	margin: 0 auto;
	z-index: 2;
}

#kl-myKudos-title {
	font-weight: 400;
	font-size: 6vmin;
	margin: 1vmin 0 0.5vmin;
	padding: 0;
	line-height: 10vmin;
	align-self: flex-end;
	color: rgba(255, 255, 255, 0.7);
	z-index: 2;
}
