#kl-main-container {
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	/* flex: 1 0 auto; */
	justify-content: flex-start;
	/* margin-top: 15px; */

	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

#kl-header-container {
	height: 8vmin;
	/* background-color: green; */
	/* display: flex; */
	/* justify-content: center; */
	margin: 5vh;
	margin-top: 4vh;
	z-index: 999;
}

#kl-header-text {
	font-weight: 400;
	font-size: 8vmin;
	margin: 0;
	/* padding: 0; */
	line-height: 10vmin;
	align-self: flex-end;
	color: #000000;
	text-align: center;
	z-index: 2;
}

#kl-tinter {
	/* background-color: rgba(255, 255, 255, 0.3); */
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 0;
}
