#kl-feed-container {
	max-width: 90vh;
	flex: 1 0 auto;
	/* padding-bottom: 6vh; */
	/* margin-top: 10px; */
	margin: 0 auto;
	overflow-y: scroll;
	/* For hiding scrollbars */
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

#kl-feed-container::-webkit-scrollbar {
	display: none;
}
