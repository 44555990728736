#kl-howTo-container {
	width: 80%;
	padding-bottom: 6vh;
	margin: 0 auto;
	z-index: 2;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	/* overflow-y: auto; */
}

#kl-howTo-container.kl-reactQuill-content {
	margin-top: 2vh;
	width: 70%;
}

.kl-reactQuill-content img {
	width: 100%;
}

.kl-howTo-textContainer {
	width: 80%;
	z-index: 2;
	margin: 0 auto;
	text-align: left;
}

.kl-howto-text {
	text-align: left;
	font-size: 3vmin;
}

.kl-howTo-lineBreak {
	height: 5vmin;
}

.kl-howTo-button {
	width: 430px;
	height: 15vw;
	background: blue;
	display: flex;
	justify-content: center;
	border-radius: 10px;
	margin: 0 auto;
	z-index: 2;
}

.kl-howTo-buttonText {
	text-align: center;
	align-self: center;
	font-size: 5vmin;
}

.kl-howTo-imageContainer {
	width: 100%;
	/* margin: 0 auto; */
	text-align: center;
	/* height: auto; */
	/* display: flex; */
	/* justifyContent: center; */
	z-index: 2;
}

.kl-howTo-image {
	width: 100%;
}
