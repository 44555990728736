.kl-badge-wrapper {
	/* background-color: black; */
	border-radius: 12vmin;
	/* height: 12vmin; */
	width: 12vmin;

	margin: 0 3vmin;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	/* position: absolute; */
	/* top: 0, */
}

.kl-badge-selected {
	background-color: rgba(0, 0, 0, 0.4);
}

.kl-badge-image {
	width: 12vmin;
	height: 12vmin;
	object-fit: scale-down;
}

.kl-badge-count {
	font-size: 3vmin;
	text-align: center;
	margin: 0 auto;
	/* display: inline-block; */
	/* position: absolute; */
	/* top: 16vmin; */
}
