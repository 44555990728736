#kl-badges-container {
	cursor: pointer;
	user-select: none;
	/* -webkit-tap-highlight-color: transparent; */
	position: relative;
	/* height: 15vmin; */
	margin: 2vmin auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	box-sizing: border-box;
}
