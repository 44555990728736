.kl-selector-container {
	/* border: 1px solid blue; */
	margin: 10px;
	height: 100%;
	overflow-y: hidden;
	z-index: 999;
	display: flex;
	flex-direction: column;
}

.kl-selector-data {
	/* border: 1px solid blue; */
	height: 100%;
	overflow-y: scroll;
	/* For hiding scrollbars */
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.kl-selector-data::-webkit-scrollbar {
	display: none;
}
