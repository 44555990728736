.kl-feedEntry-container {
	width: 90vw;
	max-width: 90%;
	height: 9vmin;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	font-size: 3vmin;
	font-weight: 800;

	margin: 0px auto;
	color: #000000;
}
.kl-feedEntry-container > div {
	display: inline-block;
}

.kl-feedEntry-time {
	/* display: flex; */
	/* align-items: center; */
	font-weight: 800;
	/* width: 40%; */
	white-space: nowrap;
}

.kl-feedEntry-nameContainer {
	margin-left: 1vw;
	flex: 1;
	/* white-space: nowrap; */
	font-weight: 300;
}

.kl-feedEntry-name {
	text-align: left;
	font-size: 2.5vmin;
	/* position: relative;
  right: 5vmin; */
}

.kl-feedEntry-proposition {
	font-style: italic;
	font-size: 2.5vmin;
	margin: 0 2vmin;
	/* position: relative;
  right: 5vmin; */
}

.kl-feedEntry-iconContainer {
	/* height: 100%; */
	display: flex;
	justify-content: center;
	align-items: center;
}

.kl-feedEntry-icon {
	height: 7vmin;
	text-align: right;
	/* position: relative;
  right: 5vmin; */
}
