#kl-toggleMenu-section {
	width: 100%;
}

#kl-toggleMenu-container {
	cursor: pointer;
	max-height: 73px;
	max-width: 800px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	outline: none;
	color: #000000;
	z-index: 20;
	font-size: 3vmin;
}

#kl-toggleMenu-container > * {
	flex: 1;
}

.kl-toggleMenu-item {
	box-sizing: border-box;
	border: 0.2vmin solid #000000;
	border-top: 0.3vmin solid #000000;
	border-bottom: 0.3vmin solid #000000;
	padding: 2vmin 3.5vmin;
	font-size: 3.5vmin;
	z-index: 2;

	min-height: 8vmin;
	/* padding: 0 4px; */

	display: flex;
	justify-content: center;
	align-items: center;
}

.kl-toggleMenu-item:first-child {
	border-left: 0.3vmin solid #000000;
	border-radius: 15px 0 0 15px;
}

.kl-toggleMenu-item:last-child {
	border-right: 0.3vmin solid #000000;
	border-radius: 0 15px 15px 0;
}

.kl-toggleMenu-item .selected {
	font-weight: 800;
}

.kl-toggleMenu-item-text {
	user-select: none;
	-ms-user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	text-align: center;
}
